<template>
    <!--  用户管理  -->
    <div class="box">
        <div class="header">
            <div class="header-left">
                <search :sonPlaceholder="placeholder" @sonsearchBut="searchBut"/>
                <datePicker @selector="selector"/>
            </div>
            <!-- <button class="add-user" @click="addUser">新增用户</button> -->
        </div>
        <div class="content">
            <el-table 
                :data="userList" 
                ref="table" 
                style="width: 100%;" 
                height="100%" 
                v-loading="loading"
                element-loading-text="拼命加载中">
                <el-table-column
                    fixed
                    align="center"
                    type="index"
                    label="序号"
                    width="58">
                </el-table-column>
                <el-table-column 
                    align="center" 
                    prop="image" 
                    label="用户头像" 
                    width="200">
                    <template slot-scope="scope">
                        <el-avatar :src="scope.row.image"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="nick"
                    label="用户名">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="uid"
                    label="用户ID">
                </el-table-column>
                <!-- <el-table-column
                    align="center"
                    prop="***"
                    label="用户类型">
                </el-table-column> -->
                <el-table-column
                    align="center"
                    prop="create_time"
                    label="用户创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time | dateFormat}}
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    align="center"
                    prop="invitation_count"
                    label="累计邀请">
                </el-table-column> -->
                <!-- <el-table-column label="订单情况" align="center">
                    <template slot="header">
                        订单情况
                        <el-tooltip class="item" effect="dark" placement="bottom">
                            <div class="tooltip-txt" slot="content">
                                累计完成订单：指该用户自身下单且获得补贴的订单数量<br/>
                                已结算订单：指该用户参与分佣，且佣金已到账的订单<br/>
                                待结算订单：指该用户参与分佣，但佣金还未到账的订单
                            </div>
                            <i class="icon-icon-question iconfont icon"></i>
                        </el-tooltip>
                    </template>
                    <el-table-column
                        align="center"
                        prop="finish_order_count"
                        label="已完成订单"
                        width="152">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="settled_count"
                        label="已结算订单"
                        width="124">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="unsettled_count"
                        label="待结算订单"
                        width="152">
                    </el-table-column>
                </el-table-column> -->
                
                <el-table-column
                    align="center"
                    prop="finish_order_count"
                    label="已完成订单"
                    width="152">
                    <template slot="header">
                        已完成订单
                        <el-tooltip class="item" effect="dark" placement="bottom">
                            <div class="tooltip-txt" slot="content">
                                用户领取外卖cps红包时会显示在“用户管理”，<br/>
                                “已完成订单”仅显示用户直充卡券和在线点餐的已完成订单。
                            </div>
                            <i class="icon-icon-question iconfont icon"></i>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column 
                    align="center" 
                    fixed="right" 
                    label="操作"
                    width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="skipTo(scope.row)">详情</el-button>
                        <el-button type="text" v-if="scope.row.is_blacklist" @click="black(scope.row.uid,false,scope.row.nick,'移出黑名单')">
                            <font color="#828494">已拉黑</font>
                        </el-button>
                        <el-button type="text" v-else @click="black(scope.row.uid,true,scope.row.nick,'拉黑')">
                            <font color="#FF6056">黑名单</font>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <paging :sonpagingData="pagingData" @sonHandleSizeChange="handleSizeChange" @sonHandleCurrentChange="handleCurrentChange" />
        </div>
        <el-dialog
            title="分享该小程序码至用户/推广员，用户授权登录后，成为本渠道用户"
            :visible.sync="dialogVisible"
            >
            <div class="dialog-box">
                <img :src="QRcodeImgUrl" alt="" srcset="">
                <button @click="downloadCodeImg">保存图片至本地</button>
            </div>
            <div class="Invitation-code-box">
                <div class="Invitation-code">
                    <div>
                        邀请码：
                    </div>
                    <div>
                        {{InvitationCode}}
                    </div>
                </div>
                <div class="annotation">*用户授权登录后，填写该邀请码，可与您的渠道绑定</div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import search from '../../components/search.vue'
import paging from '../../components/paging.vue'
import datePicker from '../../components/datePicker.vue'
export default {
    components:{
        search,
        paging,
        datePicker
    },
    data() {
        return {  
            search:'',// 搜索值
            start_time:'',// 开始时间
            end_time:'',// 结束时间
            // 分页数据
            pagingData:{
                page:1,
                max_page:15,
                count:0,
            },
            placeholder:'搜索用户ID/用户名', // 搜索提示
            userList:[{}],// 用户列表
            loading: false,// 加载状态
            dialogVisible:false,
            QRcodeImgUrl:'',
            InvitationCode:''
        }
    },
    created(){
        this.getUserList()
        this.InvitationCode=JSON.parse(this.$global.getCookie('loginData')).uid
        console.log(this.InvitationCode)
    },
    updated(){
        this.$refs.table.doLayout()
    },
    methods:{
        // 获取用户列表
        async getUserList(){
            this.loading=true
            const {data:res} = await this.$http.get("/c-admin/user_list/",{
                params:{
                    page:this.pagingData.page,
                    max_page:this.pagingData.max_page,
                    start_time:this.start_time,
                    end_time:this.end_time,
                    search:this.search
                }
            })
            if(res.status!==200) return this.$message({ showClose: true, message: '获取用户列表失败', type: 'error'})
            this.userList=res.data.data
            this.pagingData.count=res.data.count
            this.loading=false
        },
        // 接收搜索组件传递过来的值
        searchBut(val){
            console.log(val)
            this.search=val
            this.getUserList()
        },
        // 接收日期选择的数据
        selector(data){
            this.start_time=data.start_time
            this.end_time=data.end_time
            this.getUserList()
        },
        //点击查看跳转
        skipTo(row){
            // console.log(row)
            this.$router.push('/user/'+ row.uid +'?username='+ row.nick)
        },
        // 监听pagesize改变
        handleSizeChange(val) {
            console.log(val)
            this.pagingData.max_page=val
            this.getUserList()
        },
        // 监听页码值
        handleCurrentChange(val) {
            console.log(val)
            this.pagingData.page=val
            this.getUserList()
        },
        async addUser(){
            this.dialogVisible=true
            const {data:res} = await this.$http.get('cps/get_mini_qrcode/',{
                params:{
                    invitation_qrcode_type:'h5'
                }
            })
            console.log(res)
            this.QRcodeImgUrl=res.data
             
        },
        downloadCodeImg() {
            // var image = new Image();
            // image.setAttribute("crossOrigin", "anonymous");
            // var _this = this;
            // image.onload = function () {
            // var canvas = document.createElement("canvas");
            // canvas.width = image.width;
            // canvas.height = image.height;
            // var context = canvas.getContext("2d");
            // context.drawImage(image, 0, 0, image.width, image.height);
            // var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download =  "photo"; // 设置图片名称
            a.href = this.QRcodeImgUrl; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
            // };
            image.src = this.QRcodeImgUrl;
        },
         black(uid,val,nick,text){
            console.log(uid,val)
            this.$confirm('是否将该【'+nick+'】【'+uid+'】'+text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(async() => {
                    const {data:res} = await this.$http.post('/c-admin/is_blacklist/',{
                        is_blacklist:val,
                        uid:uid
                    })
                    console.log(res)
                    if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
                    this.getUserList()
                    if(res.status==200) return this.$message({ showClose: true, message:text+'成功!', type: 'success'})
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
            return
            
        }
    }
}
</script>
<style lang="less" scoped>
.box{
    padding: 0 25px;
}
.header{
    display: flex;
    justify-content: space-between;
    .header-left{
        display: flex;
    }
}
.content{
    padding-top: 20px;
    height: calc(100vh - 238px);
}
/deep/.el-table--border td{
    border-right: none;
}
/deep/.el-table--border td:last-child{
     border-right: 1px solid #EBEEF5;

}
.tooltip-txt{
    line-height: 2!important;
}
.add-user{
    width: 158px;
    height: 44px;
    border: 1px solid #252530;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: white;
    white-space:nowrap;
}
.add-user:hover{
    cursor:pointer
}
/deep/.el-dialog{
    width: 568px;
}
/deep/.el-dialog__header{
    padding: 40px 89px 20px 89px;
    font-weight: 700;
    color: #252530;
    font-size: 18px;
}
/deep/.el-dialog__body{
    text-align: center;
    padding: 0 0 40px 0;
}
.dialog-box{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    img{
        width: 250px;
        height: 250px;
    }
    button{
        background: #FF6056;
        border: 1px solid #FF6056;
        box-sizing: border-box;
        border-radius: 6px;
        margin-top: 20px;
        color: white;
        width: 250px;
        height: 44px;
        white-space:nowrap;
    }
}
.Invitation-code-box{
    display: flex;
    padding-left: 93px;
    flex-direction: column;
    .Invitation-code{
        height: 46px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        div:first-child{
            font-weight: 700;
            font-size: 16px;
            height: 46px;
            display: flex;
            align-items: center;
        }
        div:last-of-type{
            height: 46px;
            width: 250px;
            border: 1px solid #cccccc;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
        }
    }
    .annotation{
        display: flex;
        color: #828494;
        font-size: 12px;
        margin-top: 15px;
    }   
}
</style>